@use 'sass:color';
@use '../utilities/variables';

button {
	background-color: variables.$off-white;
	border: 0;
	border-radius: 0;
	color: variables.$indigo;
	cursor: pointer;
	display: block;
	line-height: 1.5;
	margin-left: auto;
	margin-right: auto;
	max-width: 320px;
	min-width: 100px;
	padding: 8px 12px;
	width: 100%;

	& + button {
		margin-top: 12px;
	}

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$white;
	}

	&[disabled] {
		background-color: variables.$indigo-gray;
		color: variables.$gray;
		cursor: not-allowed;

		&:hover,
		&:active,
		&:focus {
			background-color: variables.$indigo-gray;
		}
	}
}

.button--secondary {
	background-color: variables.$indigo-light;
	color: variables.$white;

	&:hover,
	&:active,
	&:focus {
		background-color: color.scale(variables.$indigo-light, $lightness: 5%);
	}
}

#back {
	background-color: transparent;
	background-image: url('../../svg/chevron.svg');
	background-size: 40%;
	color: variables.$white;
	height: 44px;
	left: 0;
	margin: -6px 0 0;
	position: absolute;
	top: 0;
	width: 44px;
}
