@use '../utilities/variables';

input[type='checkbox'] {
	appearance: none;
	background: none;
	border-radius: 100px;
	border-width: 0;
	cursor: pointer;
	height: 100%;
	inset: 0;
	margin: 0;
	padding: 0;
	position: absolute;
	width: 100%;

	&:focus {
		border-color: transparent;
	}

	& + span {
		color: variables.$indigo-light;
		pointer-events: none;
		position: relative;
		z-index: 1;
	}

	&:checked {
		background: variables.$indigo-light;

		&:hover,
		&:active,
		&:focus {
			background: variables.$indigo-lighter;
		}

		& + span {
			color: variables.$white;
		}
	}

	&:not(:checked) {
		&:hover,
		&:active,
		&:focus {
			& + span {
				color: variables.$indigo-lighter;
			}
		}
	}
}

.checkbox-list {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	list-style: none;
	margin: 0;
	padding: 0;
}

.checkbox-list__label {
	display: inline-block;
	margin: 8px 4px;
	padding: 8px 12px;
	position: relative;
}
