@use '../utilities/variables';

#category-list {
	margin-left: -12px;
	margin-right: -12px;
}

.category-person {
	background-color: variables.$cyan;
	background-image: url('../../svg/person.svg');

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$cyan-light;
	}
}

button.category-person::after {
	content: 'Person';
}

.category-meme {
	background-color: variables.$teal;
	background-image: url('../../svg/meme.svg');

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$teal-light;
	}
}

button.category-meme::after {
	content: 'Meme';
}

.category-movie {
	background-color: variables.$red;
	background-image: url('../../svg/movie.svg');

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$red-light;
	}
}

button.category-movie::after {
	content: 'Movie';
}

.category-tv-show {
	background-color: variables.$green;
	background-image: url('../../svg/tv-show.svg');

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$green-light;
	}
}

button.category-tv-show::after {
	content: 'TV Show';
}

.category-song {
	background-color: variables.$purple;
	background-image: url('../../svg/song.svg');

	&:hover,
	&:active,
	&:focus {
		background-color: variables.$purple-light;
	}
}

button.category-song::after {
	content: 'Song';
}
