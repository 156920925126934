/* Colors: https://material.io/guidelines/style/color.html#color-color-palette */

$red: #f44336; /* 500 */
$red-light: #ef5350; /* 400 */

$orange: #ff9800; /* 500 */

$yellow: #ffeb3b; /* 500 */

$green: #4caf50; /* 500 */
$green-light: #66bb6a; /* 400 */

$blue: #2196f3; /* 500 */

$purple: #9c27b0; /* 500 */
$purple-light: #ab47bc; /* 400 */

$pink: #e91e63; /* 500 */

$cyan: #00bcd4; /* 500 */
$cyan-light: #26c6da; /* 400 */

$teal: #009688; /* 500 */
$teal-light: #26a69a; /* 400 */

$indigo-dark: #1a237e; /* 900 */
$indigo: #283593; /* 800 */
$indigo-medium: #3949ab; /* 600 */
$indigo-light: #5c6bc0; /* 400 */
$indigo-lighter: #7986cb; /* 300 */
$indigo-gray: #565971;

$gray: #ccc;
$off-white: #e8eaf6;
$white: #fff;

/* Sizes. */

$header-height: 40px;

$highlight-font-size: 28px;
$highlight-line-height: 1.2;
