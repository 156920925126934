table {
	border-spacing: 0;
	margin: 0 auto;
}

th {
	font-weight: normal;
}

th,
td {
	padding: 5px;
}
