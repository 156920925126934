@use '../utilities/variables';

#header {
	align-items: center;
	display: flex;
	justify-content: space-between;
	left: 0;
	padding: 8px;
	position: absolute;
	right: 0;
	top: 0;
	width: 100%;
}

#room-code {
	color: variables.$indigo-lighter;
	font-size: 28px;
	font-weight: bold;
}

#settings-button {
	background-color: variables.$indigo;
	background-image: url('../../svg/cog.svg');
	background-size: 60%;
	color: variables.$white;
	height: variables.$header-height;
	margin: 0;
	width: variables.$header-height;
}
