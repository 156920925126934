@use '../utilities/variables';

.icon {
	background-position: center;
	background-repeat: no-repeat;
	background-size: 50px;
	border-radius: 100px;
	display: inline-block;
	height: 80px;
	margin: 0 8px;
	min-width: 0;
	padding: 0;
	width: 80px;
}

button.icon {
	font-size: 0;
	margin-bottom: 44px;
	position: relative;

	&::after {
		color: variables.$white;
		display: block;
		font-size: 16px;
		left: 0;
		padding-top: 4px;
		position: absolute;
		right: 0;
		top: 100%;
	}
}
