.flex {
	align-items: center;
	display: flex;
}

.hide {
	display: none;
}

.mini {
	max-width: 320px;
}

.no-margin {
	margin-top: 0;
}

.text {
	font-size: 18px;
	margin-top: 20px;
}
