fieldset,
p {
	margin-bottom: 0;
	margin-top: 20px;
}

form,
section {
	position: relative;
	width: 100%;
}
