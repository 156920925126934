@use 'sass:color';
@use '../utilities/variables';

input {
	background: variables.$indigo-medium;
	border: 0;
	border-radius: 0;
	color: variables.$white;
	line-height: 1.5;
	padding: 8px;
	width: 100%;

	&:focus {
		background-color: color.scale(variables.$indigo-medium, $lightness: 10%);
		position: relative;
		z-index: 1;
	}
}

#code {
	text-transform: uppercase;
}

fieldset {
	border-color: variables.$indigo-light;
	border-style: solid;
	border-width: 1px;
}

legend {
	font-size: 20px;
	font-weight: normal;
	margin: 0 auto;
	padding: 0 4px;
	text-align: center;
}
