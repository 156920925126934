@use '../utilities/variables';

.modal {
	background: variables.$indigo;
	border: 0;
	box-shadow: 0 0 50px variables.$indigo-dark;
	color: variables.$white;
	padding: 24px;
	position: relative;
}

#modal-close {
	background-color: transparent;
	background-image: url('../../svg/x.svg');
	background-size: 40%;
	height: 44px;
	margin: 0;
	position: absolute;
	right: -12px;
	top: -12px;
	width: 44px;
}

.modal::backdrop {
	background-color: transparent;
}
