@font-face {
	font-display: swap;
	font-family: Raleway;
	font-style: italic;
	font-weight: normal;
	src: url('../../fonts/raleway-semibolditalic-webfont.woff2') format('woff2'), url('../../fonts/raleway-semibolditalic-webfont.woff') format('woff');
}

@font-face {
	font-display: swap;
	font-family: Raleway;
	font-style: normal;
	font-weight: normal;
	src: url('../../fonts/raleway-semibold-webfont.woff2') format('woff2'), url('../../fonts/raleway-semibold-webfont.woff') format('woff');
}
