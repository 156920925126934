@use '../utilities/variables';

.field {
	align-items: center;
	display: flex;
	margin-top: 20px;
}

.field__input-wrapper {
	display: flex;
	flex: 1 1 auto;
}

.field__input-wrapper--invalid {
	box-shadow: 0 0 0 4px rgba(variables.$red, .5);
}

.field-error {
	color: variables.$red;
	font-size: 16px;
	margin-top: 8px;
	text-align: right;

	#modal & {
		margin-top: 4px;
		text-align: center;
	}
}
