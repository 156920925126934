@use '../utilities/variables';

#clue {
	align-items: center;
	display: flex;
	height: calc((variables.$highlight-font-size * variables.$highlight-line-height) * 3);
	justify-content: center;
}

img {
	height: 200px;
	width: auto;
}
