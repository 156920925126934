@use '../utilities/variables';

#players {
	list-style: none;
	margin: 0;
	padding: 0;
}

.player {
	background: variables.$indigo-dark;
	border-radius: 100px;
	margin: 8px 0 0;
	padding: 8px;
}
