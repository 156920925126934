@use 'sass:color';
@use '../utilities/variables';

.toast-container {
	align-items: flex-end;
	bottom: 0;
	display: flex;
	flex-direction: column;
	pointer-events: none;
	position: fixed;
	right: 0;
	z-index: 9999;
}

.toast {
	align-items: center;
	animation-fill-mode: forwards;
	animation-name: slide-in-out;
	background-color: variables.$indigo-dark;
	color: variables.$white;
	display: flex;
	line-height: 1;
	margin: 8px;
	max-width: 90vw;
	padding: 12px;
	pointer-events: auto;

	.modal-open & {
		background: color.scale(variables.$indigo-dark, $lightness: -20%);
	}
}

@keyframes slide-in-out {
	0% {
		transform: translateX(200%) translateZ(0);
	}

	10% {
		transform: translateX(0%) translateZ(0);
	}

	90% {
		transform: translateX(0%) translateZ(0);
	}

	100% {
		transform: translateX(200%) translateZ(0);
	}
}
