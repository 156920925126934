@use '../utilities/variables';

h1,
.highlight {
	font-size: variables.$highlight-font-size;
	font-weight: normal;
	line-height: variables.$highlight-line-height;
}

h1 {
	margin: 0 auto;
	max-width: 500px;
}
